import React from "react";
import { graphql } from "gatsby";

import Layout from "../templates/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";


export const pageQuery = graphql`
    query Show {
        mdx(frontmatter: {title: {eq: "Annual Show"}}) {
            body
            frontmatter {
              title
            }
        }
    }
`


class Show extends React.Component {
    render() {
        const data = this.props.data.mdx;

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <MDXRenderer>{data.body}</MDXRenderer>
            </Layout>
        )
    }
}
        
export default Show